import { Route, Routes } from '@angular/router';
import * as _ from 'lodash';

import { HealthcheckComponent } from '../../components/pages/healthcheck/healthcheck.component';
import { LandingPageComponent } from '../../components/pages/landing-page/landing-page.component';
import { PageNotFoundComponent } from '../../components/pages/page-not-found/page-not-found.component';
import { AuthGuard } from '../guards/auth.guard';

export const appRoutes: Routes = [

  { path: 'healthcheck', component: HealthcheckComponent },
  {
    path: 'rx-performance',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/rx-performance/rx-performance-routing.module').then((m) => m.RxPerformanceRoutingModule),
  },
  {
    path: 'rx-revcycle',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/rx-revcycle/rx-revcycle-routing.module').then((m) => m.RxRevcycleRoutingModule),
  },
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent },
];

export function componentFromRoute(route: string): Route {
  const myRoute = _.find(appRoutes, { path: route });
  return myRoute;
}
