import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '../../environments/environment';
import { Dashboard } from "../models/dashboards";
import { Pagination } from "../models/pagination";
import { GlobalFilterService } from "./global-filter.service";

@Injectable({
  providedIn: 'root',
})
export class LookerAdminService {
  private readonly Timeout: number = 30000;
  apiRoot: string;
  storeDashboards: Dashboard;

  constructor(private http: HttpClient, private globalFilterService: GlobalFilterService,
    private cookieService: CookieService) {
    this.apiRoot = environment.lookerAdminRoot;
  }

  getDashboardItems(
    serviceName?: string
  ): Observable<Dashboard> {
    const authheaders = new HttpHeaders();
    const jwt = this.cookieService.get('id_token');
    if (jwt) {
      authheaders.append('Authorization', 'Bearer ' + jwt);
      authheaders.append('Content-Type', 'application/json');
      authheaders.append('Access-Control-Allow-Origin', '*');
      authheaders.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    }
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get<Dashboard>(`${this.apiRoot}/api/Dashboards/Clients/${this.globalFilterService.selectedClientId}/Services/${serviceName}`, { headers: authheaders });
  }

  private subject = new Subject<any>();
  private storedSlugId:string = "home"

  sendMessage(message: boolean) {
      this.subject.next({ isComplete: message });
  }

  onMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  storeSlugId(slugid: string) {
    this.storedSlugId = slugid;
  }

  getStoredSlugId() : string {
    return this.storedSlugId;
  }
}
