import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Section } from 'src/app/models/section';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { GlobalFilterService } from 'src/app/services/global-filter.service';
import { LookerAdminService } from 'src/app/services/looker-admin.service';

@Component({
  selector: 'app-slug-menu-side-nav',
  templateUrl: './slug-menu-side-nav.component.html',
  styleUrls: ['./slug-menu-side-nav.component.scss']
})
export class SlugMenuSideNavComponent implements OnInit {
  public sectionsObj: Section;
  private lastModule: string;
  sectionsmenu: any[];
  routeActivated = false;
  
  @ViewChild('lookerlink') lookerlink: ElementRef;
  @Output()loadComplete: EventEmitter<any> = new EventEmitter<any>();
  @Input() parent: string;
  @Input() sections: any[];
  @Input() dashboardServiceName: string;
  @Input() featureName: string;
  
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService,
    private lookerAdminService: LookerAdminService,
    private globalFilterService: GlobalFilterService,
  ) {
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.globalFilterService.ClientsLoaded.subscribe(() => this.onClientsLoaded());
  }

  ngOnInit(): void {

    this.sectionsmenu = [
      {
        name: 'Home',
        path: 'analytics',
        feature: this.featureName,
        url: 'analytics',
        subSection: null,
        slugid: 'home'
      }
    ];
    var componentPath = this.router.url.split('/')[1];
    this.lookerAdminService.getDashboardItems(this.dashboardServiceName).subscribe({
      next: (result) => {
        if (!result || !result.items) {
          return;
        }
        var dashboardItems = result.items;
        this.lookerAdminService.storeDashboards = result;

        var hasSlugId = false;
        var storedSlugId = this.lookerAdminService.getStoredSlugId();
        if (!storedSlugId) {
          storedSlugId = "home";
        }
        dashboardItems.forEach((dashboardItem) => {
          if (dashboardItem.slugId === storedSlugId) {
            hasSlugId = true;
          }
          this.sectionsmenu.push({"name": dashboardItem.dashboardName, "path": "analytics", "feature": this.featureName, url: "analytics", "subSection": null, "slugid": dashboardItem.slugId });
        });

        this.loadComplete.emit(true);
        if (!hasSlugId) {

          this.router.navigate([componentPath + '/analytics/home']);
        }
      },
      error: (err) => {
        this.loadComplete.emit(true);
        this.router.navigate([componentPath + '/analytics/home']);
        toastr.error('We are unable to load Dashboards at this time');
      },
    });

  }

  onClientsChanged(): void {
    this.loadComplete.emit(false);
    this.ngOnInit();
  }

  onClientsLoaded(): void {
    this.loadComplete.emit(true);
  }

  checkValidLink(feature: string): boolean {
    if (!feature) {
      return false;
    }
    if (!this.authorizationService.hasFeature(feature)) {
      return false;
    }
    return true;
  }

  routeChanged(): void {
    this.routeActivated = true;
    if (this.authorizationService.HealthcareTopBar) {
      this.authorizationService.HealthcareTopBar.notifyActivity();
    }
  }

  // this function dynamically checks for access to and redirects
  // the user to the first router linking within the specified
  // parent.
  redirectToFirstLink(): void {
    let run = true;

    if (this.parent !== this.lastModule && this.sectionsmenu.length > 0) {
      this.sectionsmenu.forEach((item: any) => {
        if (run && this.authorizationService.hasFeature(item.feature)) {
          run = false;
          this.lastModule = this.parent;
          this.router.navigate([`/${this.parent}/${item.path}`]);
        }
      });
    }
  }
}
