import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RxPerformanceSummaryComponent } from './rx-performance-summary/rx-performance-summary.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { NoAccessComponent } from '../pages/no-access/no-access.component';
import { FeatureGuard } from 'src/app/core/guards/feature.guard';
import { RxPerformanceViewComponent } from './rx-performance-view/rx-performance-view.component';

const routes: Routes = [{
  path: 'rx-performance',
  component: RxPerformanceSummaryComponent,
  children: [
    { path: '', redirectTo: 'analytics/home', pathMatch: 'prefix'},
    { path: 'analytics', redirectTo: 'analytics/:slugid', pathMatch:'full'},
    {
      path: 'analytics/:slugid',
      component: RxPerformanceViewComponent,
      canActivate: [AuthGuard, FeatureGuard],
      data: { feature: 'UI::BusinessIntelligence::RxPerformance', shouldDetach: true },
    },
    {
      path: 'no-access',
      component: NoAccessComponent
    }
  ],
  runGuardsAndResolvers: 'always'
},
  {
    path: 'no-access',
    component: NoAccessComponent
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RxPerformanceRoutingModule { }
