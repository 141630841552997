import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Theme } from 'b2b-theme';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../../../environments/environment';
import { Section } from '../../../models/section';
import { SelectItem } from '../../../models/select-item';
import { AuthorizationService } from '../../../services/authorization.service';
import { GlobalFilterService } from '../../../services/global-filter.service';

declare function intializeHealthcareTopBar(
  parentContainer: JQuery,
  authzApiUrl: string
): void;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private topBarLoaded = false;
  public readonly apptitle = environment.applicationDisplayName;
  readonly healthcareBiLink = `${environment.biUrlRoot}/#`;

  public usersClients: SelectItem[] = [];
  public clientsLoaded = false;

  @Input() sections: Section[];
  @ViewChild('ul') ul: ElementRef;
  @ViewChild('sidenav') public sidenav: MatSidenav;
  hamburger = false;
  public innerWidth: any;
  public sectionNameLength: any;

  constructor(
    private globalFilterService: GlobalFilterService,
    private authorizationService: AuthorizationService,
    private cookieService: CookieService
  ) {
    // Latch to prevent double-calls
    if (this.topBarLoaded) {
      return;
    }

    this.innerWidth = window.innerWidth;
    this.injectHealthcareTopBar()
      .catch((error) => {
        console.log(error);
      })

      .then(() => {
        const topBar = intializeHealthcareTopBar($('#healthcareTopBar'), environment.authZApiRoot,
        );
        // window.healthcareTopBar = topBar;
        window['healthcareTopBar'] = topBar;
        this.topBarLoaded = true;
        this.authorizationService.setHealthcareTopBar(topBar);
      })
      .finally(() => {
        Theme;
      })


      this.globalFilterService.ClientsLoaded.subscribe(
        (response) => {
          _.forEach(this.globalFilterService.clients, (client) => {
            this.usersClients.push({ label: `${client.ClientId} - ${client.ClientName}`, value: client.ClientId });
          });
          this.clientsLoaded = true;
        }
      );
      this.globalFilterService.loadClients();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth >= 1500) {
      this.sidenav.close();
    }
    this.setHamburger();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getSectionNameLength();
    this.setHamburger();
  }

  clientSelected(event: any) {
    this.globalFilterService.selectedClientId = event.value;
  }

  injectHealthcareTopBar(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = environment.healthcareTopBar;
      script.addEventListener('load', resolve);
      script.addEventListener('error', () => reject('Error loading script.'));
      script.addEventListener('abort', () => reject('Script loading aborted.'));
      document.body.appendChild(script);
    });
  }

  checkValidLink(feature: string): boolean {
    if (!feature || !this.authorizationService.hasFeature(feature)) {
      return false;
    }
    return true;
  }

  hasFeature(name: string): boolean {
    const result = name ? this.authorizationService.hasFeature(name) : false;
    return result;
  }

  setHamburger(): void {
    this.hamburger = false;
    if (this.sectionNameLength > 50) {
      this.hamburger = true;
    }
    if (this.innerWidth < 1470) {
      this.hamburger = true;
    }
  }

  getSectionNameLength(): void {
    const tempSectionsArray = this.sections;
    this.sectionNameLength = tempSectionsArray
      .map((item) => item.name.length)
      .reduce((prev, next) => prev + next);
  }
}
