import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { HealthcheckComponent } from './components/pages/healthcheck/healthcheck.component';
import { NoAccessComponent } from './components/pages/no-access/no-access.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import * as routes from './core/routes/routes';
import { AuthorizationService } from './services/authorization.service';
import { BaseService } from './services/base.service';
import { TokenInterceptorService } from './core/interceptor/token-interceptor.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CookieService } from 'ngx-cookie-service';
import { GlobalFilterService } from './services/global-filter.service';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { SideNavComponent } from './components/layout/side-nav/side-nav.component';
import { RxPerformanceModule } from './components/rx-performance/rx-performance.module';
import { RxRevcycleModule } from './components/rx-revcycle/rx-revcycle.module';
import { SlugMenuSideNavComponent } from './components/shared/slug-menu-side-nav/slug-menu-side-nav.component';
import { SharedModule } from './components/shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        HealthcheckComponent,
        PageNotFoundComponent,
        SideNavComponent,
        NoAccessComponent,
        LandingPageComponent
    ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes.appRoutes, {
      enableTracing: !environment.production,
      onSameUrlNavigation: 'reload',
      useHash: true
    }),
    InputTextModule,
    PanelModule,
    PasswordModule,
    DropdownModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatToolbarModule,
    RxPerformanceModule,
    RxRevcycleModule,
    SharedModule,
    MatIconModule
  ],
  exports: [

  ],
    providers: [
        AuthorizationService,
        BaseService,
        GlobalFilterService,
        CookieService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
