import { Injectable, ɵConsole } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import * as _ from 'lodash';

import { environment } from '../../../environments/environment';
import { AuthorizationService } from '../../services/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard implements CanActivate {
  private topBarLoaded = false;
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const self = this;
    const promise = new Promise((resolve, reject) => {
      if (!this.authorizationService.hasTopBarLoaded) {
        this.authorizationService.healthcareLoadedSubject$.subscribe(() => {
          let found = false;
          const featureName = route.data.feature;
          found =
            _.find(
              this.authorizationService.HealthcareTopBar.currentUserDetails.Features,
              { Name: featureName, App: environment.applicationNameBi }
            ) ||
            _.find(
              this.authorizationService.HealthcareTopBar.currentUserDetails.Features,
              { Name: featureName, App: environment.applicationNameBi }
            )
              ? true
              : false;
          resolve(found);
        });
      } else {
        let found = false;
        const featureName = route.data.feature;
        found =
          _.find(this.authorizationService.HealthcareTopBar.currentUserDetails.Features, {
            Name: featureName,
            App: environment.applicationNameBi,
          }) ||
          _.find(this.authorizationService.HealthcareTopBar.currentUserDetails.Features, {
            Name: featureName,
            App: environment.applicationNameBi,
          })
            ? true
            : false;
        resolve(found);
      }
    });

    return promise.then(
      (res: boolean) => {
        if (res) {
          return true;
        }
        self.router.navigate(['/no-access']);
        return false;
      },
      (error) => {
        self.router.navigate(['/no-access']);
        return false;
      }
    );
  }
}
