import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as toastr from 'toastr';

import { Section } from './models/section';
import { AuthorizationService } from './services/authorization.service';

// import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [],
})
export class AppComponent implements OnInit {
  title = environment.applicationDisplayName;
  public currentYear = new Date().getFullYear();
  sections: Section[] = [
    {
      name: 'RX PERFORMANCE',
      path: 'rx-performance/',
      feature: 'UI::BusinessIntelligence::RxPerformance',
      url: '/analytics',
      isLegacyLink: false,
      subSection: null,
    },
    {
      name: 'RX REVCYCLE',
      path: 'rx-revcycle/',
      feature: 'UI::BusinessIntelligence::RxRevCycle',
      url: '/analytics',
      isLegacyLink: false,
      subSection: null,
    }
  ];

  apiRoot: string;
  constructor(private authorizationService: AuthorizationService) {
    this.setToasterOptions();
  }

  setToasterOptions(): void {
    toastr.options.showDuration = 1000;
    toastr.options.hideDuration = 1000;
    toastr.options.timeOut = 3000;
    toastr.options.extendedTimeOut = 2000;
    toastr.options.showEasing = 'swing';
    toastr.options.hideEasing = 'linear';
    toastr.options.showMethod = 'fadeIn';
    toastr.options.hideMethod = 'fadeOut';
  }

  ngOnInit(): void {
    this.apiRoot = environment.lookerAnalyticsRoot;
  }

  routeChanged(): void {
    if (this.authorizationService.HealthcareTopBar) {
      this.authorizationService.HealthcareTopBar.notifyActivity();
    }
  }
}
