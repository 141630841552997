import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RxRevcycleRoutingModule } from './rx-revcycle-routing.module';
import { RxRevcycleSummaryComponent } from './rx-revcycle-summary/rx-revcycle-summary.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { RxRevcycleViewComponent } from './rx-revcycle-view/rx-revcycle-view.component';


@NgModule({
  declarations: [
    RxRevcycleSummaryComponent,
    RxRevcycleViewComponent
  ],
  imports: [
    CommonModule,
    RxRevcycleRoutingModule,
    MatProgressSpinnerModule,
    SharedModule,
    FlexLayoutModule
  ]
})
export class RxRevcycleModule { }
