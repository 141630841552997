import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Section } from '../../../models/section';
import { AuthorizationService } from '../../../services/authorization.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  public sectionsObj: Section;
  private lastModule: string;
  sectionsmenu: any[];
  @Input() parent: string;
  @Input() sections: any[];
  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit(): void {
    switch (this.parent) {
      case 'workflow':
        this.sectionsmenu = [
          {
            name: 'LEFT NAV ITEM 1',
            path: 'workflowsection',
            feature: '',
            url: '',
            subSection: null,
          }
        ];
        break;
      default:
        this.sectionsmenu = [
          {
            name: 'LEFT NAV ITEM 1',
            path: 'workflowsection',
            feature: '',
            url: '',
            subSection: null,
          }
        ];
    }
  }
  checkValidLink(feature: string): boolean {
    if (!feature) {
      return false;
    }
    if (!this.authorizationService.hasFeature(feature)) {
      return false;
    }
    return true;
  }

  // this function dynamically checks for access to and redirects
  // the user to the first router linking within the specified
  // parent.
  redirectToFirstLink(): void {
    let run = true;

    if (this.parent !== this.lastModule && this.sectionsmenu.length > 0) {
      this.sectionsmenu.forEach((item: any) => {
        if (run && this.authorizationService.hasFeature(item.feature)) {
          run = false;
          this.lastModule = this.parent;
          this.router.navigate([`/${this.parent}/${item.path}`]);
        }
      });
    }
  }
}
