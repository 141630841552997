<app-header [sections]="sections">
</app-header>
<main class="inm-layout-main">

  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  </head>
  <router-outlet></router-outlet>
</main>
<a href="#" class="inm-skip-to-main js-inm-skip-to-main">Skip to main content</a>
<footer class="inm-layout-footer">&copy; Inmar, Inc.</footer>
