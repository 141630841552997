import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlugMenuSideNavComponent } from './slug-menu-side-nav/slug-menu-side-nav.component';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [
    SlugMenuSideNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    FlexLayoutModule
  ],
  exports: [
    SlugMenuSideNavComponent
  ]
})
export class SharedModule { }
