import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RxPerformanceRoutingModule } from './rx-performance-routing.module';
import { RxPerformanceSummaryComponent } from './rx-performance-summary/rx-performance-summary.component';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedModule } from '../shared/shared.module';
import { RxPerformanceViewComponent } from './rx-performance-view/rx-performance-view.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    RxPerformanceSummaryComponent,
    RxPerformanceViewComponent
  ],
  imports: [
    CommonModule,
    RxPerformanceRoutingModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    SharedModule,
  ],
  exports: [
  ]
})
export class RxPerformanceModule { }
