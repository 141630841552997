<section class="inm-layout-sidebar">
  <nav id="navbar" class="inm-expandable--sidebar inm-layout-sidebar__nav">
    <!-- <app-rx-performance-side-nav (loadComplete)="sideNavDashboardItemsComplete($event)"></app-rx-performance-side-nav> -->
    <app-slug-menu-side-nav 
      (loadComplete)="sideNavDashboardItemsComplete($event)"
      [dashboardServiceName]="serviceName"
      [featureName]="feature">
    </app-slug-menu-side-nav>
  </nav>
  <div class="inm-layout-sidebar__content-area">
    <!-- main site content goes here -->
    <section class="">
      <router-outlet (activate)="routeChanged()"> </router-outlet>
    </section>
  </div>
</section>
