import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { AuthorizationService } from '../../services/authorization.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authorizationService: AuthorizationService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cookieService.check('id_token')) {
      if (this.authorizationService.HealthcareTopBar) {
        this.authorizationService.HealthcareTopBar.notifyActivity();
      }

      req = req.clone({
        setHeaders: { Authorization: `Bearer ${this.cookieService.get('id_token')}` },
      });
    }
    return next.handle(req);
  }
}
