import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { takeWhile } from 'rxjs';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { GlobalFilterService } from 'src/app/services/global-filter.service';
import { LookerAdminService } from 'src/app/services/looker-admin.service';
import { LookerAnalyticsService } from 'src/app/services/looker-analytics.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-rx-revcycle-view',
  templateUrl: './rx-revcycle-view.component.html',
  styleUrls: ['./rx-revcycle-view.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RxRevcycleViewComponent implements OnInit, OnDestroy{
  UntrustedUrl: string;
  SafeUrl: SafeResourceUrl;
  iFrameReady: boolean;
  iFrameLoadComplete: boolean;
  slugId: string;
  analyticsHome: boolean = false;
  absoluteUrl = null;
  isAlive = true;

  origin = environment.lookerAnalyticsRoot;

  @ViewChild('looker') looker: ElementRef;

  @HostListener('window:message', ['$event'])
  onhostListener(event) {
    this.receiveMessage(event);
  }

  constructor(private sanitizer: DomSanitizer,
    private authorizationService: AuthorizationService,
    private globalFilterService: GlobalFilterService,
    private lookerAnalyticsService: LookerAnalyticsService,
    private lookerAdminService: LookerAdminService,
    private route: ActivatedRoute,
  ) {
    this.globalFilterService.ClientChanged.subscribe(() => this.onClientsChanged());
    this.globalFilterService.ClientsLoaded.subscribe(() => this.onClientsLoaded());
    this.route.params.subscribe((params) => {
      
      if (_.size(params) > 1) {
        this.setLookerIFrame(this.globalFilterService.selectedClientId);
      }
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.slugId = params.get('slugid');
      this.lookerAdminService.storeSlugId(this.slugId);
      if (!this.slugId || this.slugId === 'home') {
        // this.iFrameLoadComplete = true;
        this.iFrameReady = false;
        this.analyticsHome = true;
        return;
      } else {
        if (this.route.snapshot.queryParamMap.get('absoluteUrl')) {
          this.absoluteUrl = this.route.snapshot.queryParamMap.get('absoluteUrl');
          this.lookerAnalyticsService.processForwardedParams(this.absoluteUrl);
        }
        this.analyticsHome = false;
        this.setLookerIFrame(this.globalFilterService.selectedClientId);
      }
    });

    this.lookerAdminService.onMessage()
          .pipe(takeWhile(() => this.isAlive)).subscribe(message => {
            this.iFrameLoadComplete = message;
    });
  }

  onClientsChanged(): void {
    this.iFrameLoadComplete = false;
    if (this.slugId === 'home') {
      return;
    }
    if (this.route.snapshot.queryParamMap.get('absoluteUrl')) {
      this.absoluteUrl = this.route.snapshot.queryParamMap.get('absoluteUrl');
      this.lookerAnalyticsService.processForwardedParams(this.absoluteUrl);
    }
    this.setLookerIFrame(this.globalFilterService.selectedClientId);
  }

  onClientsLoaded(): void {
    if (this.slugId === 'home') {
      return;
    }
    this.setLookerIFrame(this.globalFilterService.selectedClientId);
  }

  receiveMessage(event) {
    let iframe = this.looker.nativeElement.contentDocument || this.looker.nativeElement.contentWindow;
    if (event.source === iframe && event.origin === this.origin) {
      this.lookerAnalyticsService.handleEvents(event, iframe);
    }
  }

  setLookerIFrame(clientId: string) {
    this.iFrameLoadComplete = false;
    if (clientId === undefined) {
      return;
    }
    const dashboardSlug = this.slugId;

    this.authorizationService.GetLookerUrl(clientId, dashboardSlug)
      .subscribe(
        resp => {
          this.UntrustedUrl = resp;
          this.SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.UntrustedUrl);
          this.iFrameReady = true;
          this.iFrameLoadComplete = true;
          this.analyticsHome = false;
        });
  }

  onLoadComplete() {
    this.iFrameLoadComplete = true;
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
}
